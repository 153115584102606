<template>
  <footer class="footer">
    <b-container>
      <div class="footer-wrap">
        <b-row class="footer-row">
          <b-col cols="12" lg="3" md="6" sm="12" class="footer-col-1">
            <div class="about-footer-wrap">
              <div class="footer-list footer-list-padding-right">
                <FooterCustomerService />
                <FooterNewsletter />
              </div>
              <div class="footer-list">
                <FooterFollowUs />
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="3" md="6" sm="12" class="footer-col-2">
            <div class="footer-list footer-list-padding-left">
              <FooterTopTenComponent />
            </div>
            <p
              class="address-para desktop-display-none"
              style="text-align: center"
            ></p>
          </b-col>
          <b-col cols="12" lg="3" md="6" sm="12" class="footer-col-3">
            <div class="footer-list footer-list-padding-left">
              <FooterAboutUs />
              <FooterPopularComponent />
            </div>
          </b-col>

          <b-col cols="12" lg="3" md="6" sm="12" class="footer-col-4">
            <div class="footer-list footer-list-padding-left">
              <FooterOpeningTimes />
              <FooterAddress />
            </div>
          </b-col>
          <b-col cols="12" lg="3" md="12" sm="12">
            <p class="copyright">
              <a href="/sitemap_bankxl_nl.xml">{{ $t("sitemap") }}</a>
              <span
                >{{ $t("copyright") }} &copy; {{ currentYear }} HomingXL
                B.V.</span
              >
            </p>
          </b-col>
          <FooterTopScreenComponent />
        </b-row>
      </div>
    </b-container>
  </footer>
</template>
<script>
// import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple.vue";
import FooterCustomerService from "@/esf_utrecht_bankxl/core/components/footer/FooterCustomerService";
import FooterNewsletter from "@/esf_utrecht_bankxl/core/components/footer/FooterNewsletter";
import FooterFollowUs from "@/esf_utrecht_bankxl/core/components/footer/FooterFollowUs";
import FooterTopTenComponent from "@/esf_utrecht_bankxl/core/components/footer/FooterTopTenComponent";
import FooterAboutUs from "@/esf_utrecht_bankxl/core/components/footer/FooterAboutUs";
import FooterPopularComponent from "@/esf_utrecht_bankxl/core/components/footer/FooterPopularComponent";
import FooterOpeningTimes from "@/esf_utrecht_bankxl/core/components/footer/FooterOpeningTimes";
import FooterAddress from "@/esf_utrecht_bankxl/core/components/footer/FooterAddress";
import FooterTopScreenComponent from "@/esf_utrecht_bankxl/core/components/footer/FooterTopScreenComponent";
export default {
  name: "Footer",
  components: {
    FooterPopularComponent,
    FooterCustomerService,
    FooterNewsletter,
    FooterFollowUs,
    FooterTopTenComponent,
    FooterAboutUs,
    FooterOpeningTimes,
    FooterAddress,
    FooterTopScreenComponent,
    // cmsBlockSimple,
  },
  data: function () {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
