<template>
  <div class="popular-link">
    <div class="contacts-block" v-if="typeof populair == 'object'">
      <cmsBlockSimple
        v-if="typeof populair == 'object'"
        :identifier="populair.identifier"
      />
    </div>
  </div>
</template>
<script>
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "FooterPopularComponent",
  components: {
    cmsBlockSimple,
  },
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  computed: {
    populair() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_populair"
      );
    },
  },
};
</script>
