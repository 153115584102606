<template>
  <div class="follow-us">
    <cmsBlockSimple
      v-if="typeof social_links == 'object'"
      :identifier="social_links.identifier"
    />
    <div class="devider show-mobile"></div>
  </div>
</template>
<script>
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple.vue";
export default {
  name: "FooterFollowUs",
  props: {
    msg: String,
  },
  components: {
    cmsBlockSimple,
  },
  computed: {
    social_links() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_social_links"
      );
    },
  },
};
</script>