<template>
  <div class="about-link">
    <div class="contacts-block" v-if="typeof over_homing_xl == 'object'">
      <cmsBlockSimple
        v-if="typeof over_homing_xl == 'object'"
        :identifier="over_homing_xl.identifier"
      />
    </div>
  </div>
</template>
<script>
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple.vue";

export default {
  name: "FooterAboutUs",
  components: {
    cmsBlockSimple,
  },
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  computed: {
    over_homing_xl() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_over_homing_xl"
      );
    },
  },
};
</script>
