<template>
  <div class="top-ten-list">
    <div class="contacts-block" v-if="typeof top10_bij_homing_XL == 'object'">
      <cmsBlockSimple
        v-if="typeof top10_bij_homing_XL == 'object'"
        :identifier="top10_bij_homing_XL.identifier"
      />
    </div>
  </div>
</template>
<script>
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "FooterTopTenComponent",
  components: {
    cmsBlockSimple,
  },
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  computed: {
    top10_bij_homing_XL() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_top10_bij_homing_XL"
      );
    },
  },
};
</script>
