<template>
  <div class="address">
    <div class="contacts-block" v-if="typeof adres == 'object'">
      <cmsBlockSimple
        v-if="typeof adres == 'object'"
        :identifier="adres.identifier"
      />
    </div>
  </div>
</template>
<script>
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "FooterAddress",
  components: {
    cmsBlockSimple,
  },
  props: {
    msg: String,
  },

  data() {
    return {};
  },
  computed: {
    adres() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_adres"
      );
    },
  },
};
</script>
