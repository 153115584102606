<template>
  <div
    class="header navbar-static-top"
    :class="{ 'navbar--hidden': !showNavbar }"
  >
    <div class="desktop-header">
      <b-container>
        <HeaderTop msg="" />
      </b-container>

      <HeaderSearch msg="" />
      <div class="header-top-megamenu">
        <b-container>
          <HeaderTopMenu />
        </b-container>
      </div>
    </div>
    <MobileHeaderComponent />
  </div>
</template>

<script>
import MobileHeaderComponent from "@/esf_utrecht_bankxl/core/components/header/MobileHeaderComponent.vue";
import HeaderTop from "@/esf_utrecht_bankxl/core/components/header/HeaderTop.vue";
import HeaderSearch from "@/esf_utrecht_bankxl/core/components/header/HeaderSearch.vue";
import HeaderTopMenu from "@/esf_utrecht_bankxl/core/components/header/HeaderTopMenu.vue";

import { isServer } from "@storefront/core/helpers";

export default {
  name: "Header",
  components: {
    HeaderTop,
    HeaderSearch,
    HeaderTopMenu,
    MobileHeaderComponent,
  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    if (!isServer) {
      window.addEventListener("scroll", this.onScroll);
    }
  },
  beforeDestroy() {
    if (!isServer) {
      window.removeEventListener("scroll", this.onScroll);
    }
  },
  methods: {
    onScroll() {
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
