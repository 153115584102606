<template>
  <b-link class="top-screen show" v-show="visible" @click="scrollTop"
    ><b-img alt="" :src="topscreenLogo"
  /></b-link>
</template>
<script>
import topscreenLogo from "@/esf_utrecht_bankxl/assets/images/topscreen-logo.png";
export default {
  name: "FooterTopScreenComponent",
  props: {
    msg: String,
  },
  data() {
    return {
      topscreenLogo,
      visible: false,
    };
  },
  methods: {
    scrollTop: function () {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>