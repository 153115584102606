<template>
  <div class="opening-time">
    <div class="contacts-block" v-if="typeof contactData == 'object'">
      <cmsBlockSimple
        v-if="typeof contactData == 'object'"
        :identifier="contactData.identifier"
      />
    </div>
  </div>
</template>
<script>
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "FooterOpeningTimes",
  components: {
    cmsBlockSimple,
  },
  props: {
    msg: String,
  },

  data() {
    return {};
  },
  computed: {
    contactData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_about_homing_xl"
      );
    },
  },
};
</script>
