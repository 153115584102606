<template>
  <div class="newsletter">
    <div class="contacts-block" v-if="typeof laat_je_inspireren == 'object'">
      <cmsBlockSimple
        v-if="typeof laat_je_inspireren == 'object'"
        :identifier="laat_je_inspireren.identifier"
      />
    </div>
    <div class="custom-inline-form">
      <b-form class="form-inline" v-on:submit.prevent>
        <div class="form-group">
          <label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="E-mailadres"
              v-model="email"
              @keyup.enter.prevent="subscribeNewsletter(email)"
            />
            <button
              type="button"
              @click="subscribeNewsletter(email)"
              class="custom-btn btn"
            >
              {{ $t("surprise_me") }}!
            </button></label
          >
        </div>
      </b-form>
    </div>
    <div class="devider show-mobile"></div>
  </div>
</template>
<script>
import User from "@storefront/core/modules/user/mixins/index";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "FooterNewsletter",
  mixins: [User],
  components: {
    cmsBlockSimple,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      email: "",
    };
  },
  computed: {
    laat_je_inspireren() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_laat_je_inspireren"
      );
    },
  },

  methods: {
    async subscribeNewsletter(email) {
      await this.subscribe(email);
      this.email = "";
    },
  },
};
</script>
