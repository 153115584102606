<template>
  <div class="my-account-wishlist-card">
    <b-modal
      ref="product-delete-wish"
      id="product-delete-wish"
      class="product-modal-inner"
      content-class="defaul-modal"
      hide-footer
      centered
      size="lg"
    >
      <template #modal-title class="bold"
        >{{ $t("remove_product_wishlist") }}
      </template>
      <div class="modal-text d-block border-bottom">
        <p>
          {{ $t("sure_remove_product_wishlist") }}
        </p>
        <!-- <p>Wil je deze producten bewaren voor later maak een account aan</p> -->
      </div>
      <div class="modal-btn-box pt-20">
        <b-button class="btn-success" block @click="clickOk">Ok</b-button>
        <b-button class="btn-success btn-outline" block @click="clickCancel">{{
          $t("cancel")
        }}</b-button>
      </div>
    </b-modal>
    <b-link class="wishlist-icon" @click="removeFromWishList(wishItem.product)">
      <font-awesome-icon icon="heart" />
    </b-link>
    <div class="left-h">
      <b-img
        :src="wishItem.product.image.url"
        alt=""
        v-if="wishItem && wishItem.product && wishItem.product.image"
      />
    </div>
    <div class="left-r">
      <div class="product-acc-content">
        <div class="product-acc-text">
          <p>
            {{ wishItem.product.name }}
          </p>
        </div>
        <div class="d-flex cart-info-btn-wrap">
          <!-- <b-col cols="6" class="wish-pro-cart"> -->
          <div class="wish-pro-cart mr-2">
            <button class="btn btn-success" @click="addToCart()">
              <span class="icon-cart"><i class="fa fa-plus"></i></span>
              <font-awesome-icon icon="shopping-cart" class="cart-icon" />
            </button>
          </div>
          <!-- </b-col> -->
          <!-- <b-col cols="6" class="more-info"> -->
          <div class="more-info">
            <button
              type="button"
              class="btn btn-success"
              @click="$router.push('/' + wishItem.product.url_key + '/')"
            >
              {{ $t("more_info") }}
            </button>
          </div>
          <!-- <span class="tab-link-arrow">
            </span> -->
          <!-- </b-col> -->
        </div>
      </div>
    </div>
    <!-- @click="removeFromWishList(wishItem.product)" -->
    <b-link class="delete-icon-card" @click="showPopup">
      <font-awesome-icon icon="trash-alt" />
    </b-link>
  </div>
</template>

<script>
export default {
  name: "WishlistCard",
  props: ["wishItem"],
  computed: {
    isInWishlist() {
      return this.$store.getters["wishlist/isProductInWishlist"](
        this.wishItem.product.sku
      );
    },
  },
  methods: {
    removeFromWishList(product) {
      this.$emit("removeProduct", product);
    },
    clickOk() {
      this.$bvModal.hide("product-delete-wish");
      this.UpdateIsInWishList();
    },
    clickCancel() {
      this.$bvModal.hide("product-delete-wish");
    },
    showPopup() {
      this.$refs["product-delete-wish"].show();
    },
    UpdateIsInWishList() {
      if (this.isInWishlist) {
        this.$store.dispatch("wishlist/removeProductFromWishlist", {
          sku: this.wishItem.product.sku,
          parentSku: null,
        });
      } else {
        if (this.wishItem.product.__typename == "SimpleProduct") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: this.wishItem.product.sku,
            parentSku: null,
          });
        } else {
          const childSku = this.$store.getters["product/getCurrentChildSku"];
          if (childSku == null) {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: childSku,
              parentSku: this.wishItem.product.sku,
            });
          }
        }
      }
    },
    async addToCart() {
      if (this.wishItem.product.__typename == "SimpleProduct") {
        const item =
          '{data: {sku: "' +
          this.wishItem.product.sku +
          '", quantity:' +
          1 +
          "}}  ";
        let retval = await this.$store.dispatch("cart/addToCart", {
          type: this.wishItem.product.__typename,
          item: item,
        });

        if (retval == false) {
          const msg = {
            type: "danger",
            title: this.$t("shopping_basket"),
            text: this.$t("not_add_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          // this.$bvModal.show("modal-1");
          this.$root.$emit("openCartPopup");
        }
      } else {
        this.$router.push(`/` + this.wishItem.product.url_key + "/");
      }
    },
  },
};
</script>

<style></style>
