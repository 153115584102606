<template>
  <div class="dropdown dropdown-menu-homing">
    <div class="dropdown-toggle" @click="toggleDropdown()">
      <span>Onze Sites</span>
      <span class="nav-icon">
        <b-icon :icon="icon" />
      </span>
    </div>
    <ul class="dropdown-menu" v-show="isDropdownOpen">
      <li
        v-for="option in options"
        v-on:click="selectOption(option)"
        :key="option.id"
      >
        <b-link :href="option.url" @click="hideDropDown()">
          <img :src="option.image" class="dropdown-option-image" width="101"
        /></b-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "StoreSwitcher",
  computed: {
    slider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "store_switch"
      );
    },
    options() {
      var slides = [];
      this.slider.slides.forEach((slide) => {
        slides.push({
          image: slide.media.url,
          title: slide.title,
          url: slide.link,
        });
      });
      return slides;
    },
    defaultOption() {
      if (this.slider.slides && this.slider.slides.length > 0) {
        return {
          image: this.slider.slides[0].media.url,
          title: this.slider.slides[0].title,
        };
      }
      return {
        image: null,
        title: null,
      };
    },
  },
  created() {
    this.selectedOption = this.options[0];
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        this.icon = "chevron-up";
        setTimeout(() => {
          this.icon = "chevron-down";
          this.isDropdownOpen = false;
        }, 5000);
      } else {
        this.icon = "chevron-down";
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isDropdownOpen = false;
    },
    hideDropDown() {
      this.isDropdownOpen = false;
      this.icon = "chevron-down";
    },
  },
  data() {
    return {
      icon: "chevron-down",
      isDropdownOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.dropdown-toggle {
  cursor: pointer;

  span {
    font-weight: 600;
    color: #727272;
    letter-spacing: .2px;
    text-transform: uppercase;
  }
}
</style>