<template>
  <div>
    <div
      v-if="isActive === 'block !important'"
      class="cartOverlyPopup"
      @click.stop="closePopup"
    ></div>
    <div class="wishlist-popup" :style="{ display: isActive }">
      <div class="wishlist-popup-title">
        <h5>
          {{ $t("my_wishlist") }}
          <span>({{ wishlist.length }})</span>
        </h5>
        <div class="wishlist-popup-subtitle">
          {{ $t("find_wish_list_here") }}
        </div>
      </div>
      <div class="wishlist-popup-grid">
        <div
          class="wishlist-popup-item"
          v-for="wishItem in showProducts"
          :key="wishItem.id"
        >
          <WishlistCard
            :wishItem="wishItem"
            @removeProduct="removeFromWishList"
          />
        </div>
        <div class="wishlist-popup-buttons">
          <div v-if="!isLoggedIn">
            <button
              @click="$router.push('/create-account/')"
              class="btn btn-warning text-white"
            >
              {{ $t("account_create") }}
            </button>
          </div>
          <div>
            <!-- v-show="showLoadMore" -->
            <button
              class="btn-width wishlist-btn btn custom-button btn-success"
              @click="toWishlist"
            >
              {{ $t("show_more") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WishlistCard from "@/esf_utrecht_bankxl/core/components/wishlist/WishlistCard";
export default {
  name: "WishlistMobileComponent",
  components: { WishlistCard },
  data() {
    return {
      Window,
      step: 4,
      prodsCount: 4,
      isActive: "none",
    };
  },
  computed: {
    wishlistLength() {
      return this.$store.getters["wishlist/getWishQuantity"];
    },
    wishlist() {
      return this.$store.getters["wishlist/getWishItems"];
    },
    showProducts() {
      // return this.wishlist.slice(0, this.prodsCount);
      return this.wishlist.slice(0, 2);
    },
    showLoadMore() {
      return this.wishlist.length > this.prodsCount;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    loadMore() {
      this.prodsCount += this.step;
    },
    toWishlist() {
      this.$emit("closeWishPopup");
      this.$router.push("/wishlist/");
    },
    async removeFromWishList(product) {
      await this.$store.dispatch("wishlist/removeProductFromWishlist", {
        sku: product.sku,
      });
    },
    showPopup() {
      if (this.isActive == "none") {
        this.isActive = "block !important";
        setTimeout(() => (this.isActive = "none"), 5000);
      } else {
        this.isActive = "none";
      }
    },
    closePopup() {
      this.isActive = "none";
    },
  },
  created() {
    this.$root.$on("openWishList", this.showPopup);
  },
};
</script>
